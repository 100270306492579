/* eslint-disable no-unused-vars */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";
import Helmet from "react-helmet";
interface SEOMeta {
	content: any;
	name: string;
	property?: undefined;
}

interface SEOProps {
	description?: string;
	lang?: string;
	meta?: SEOMeta[];
	title?: string;
	type?: string;
	image?: string;
}

const SEO = ({ description, lang, meta, title, type, image }: SEOProps) => {
	const { pathname, hostname } = useLocation();

	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						defaultType
						siteUrl
						twitterUsername
						defaultImage
						siteName
					}
				}
			}
		`
	);

	const seo = {
		title: title,
		description: description || site.siteMetadata.description,
		type: type || site.siteMetadata.defaultType,
		url: `${site.siteMetadata.siteUrl}${pathname}`,
		twitterUsername: site.siteMetadata.twitterUsername,
		image: `${site.siteMetadata.siteUrl}${
			image || site.siteMetadata.defaultImage
		}`,
		siteName: site.siteMetadata.siteName,
	};

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title || site.siteMetadata.defaultTitle}
			titleTemplate={site.siteMetadata.titleTemplate}
			meta={[
				{
					name: `description`,
					content: seo.description,
				},
				{
					name: `image`,
					content: seo.image,
				},
				{
					property: `og:title`,
					content: seo.title,
				},
				{
					property: `og:description`,
					content: seo.description,
				},
				{
					property: `og:type`,
					content: seo.type,
				},
				{
					property: `og:url`,
					content: seo.url,
				},
				{
					property: `og:site_name`,
					content: seo.siteName,
				},
				{
					property: `og:image`,
					content: seo.image,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: seo.twitterUsername,
				},
				{
					name: `twitter:site`,
					content: seo.twitterUsername,
				},
				{
					name: `twitter:title`,
					content: seo.title,
				},
				{
					name: `twitter:description`,
					content: seo.description,
				},
				{
					name: `twitter:image`,
					content: seo.image,
				},
				{
					name: `twitter:url`,
					content: seo.url,
				},
			].concat(meta ? meta : [])}
		/>
	);
};

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default SEO;
